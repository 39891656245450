import config from '../config.json';
import { UserProfile } from 'src/types';


const fetchUser = async (email: string, password: string): Promise<UserProfile> => {
  // URL of your API Gateway endpoint
  const url = config.signInUrl;//'https://<api-id>.execute-api.<region>.amazonaws.com/<stage>/users/signin';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

   
    const resultEmail = data.profile.email;
    const profile = {
      ...data.profile,
      email: resultEmail,
      name:  resultEmail.split("@")[0]
    };

    return profile;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw new Error('Failed to fetch user');
  }
};

export default fetchUser;

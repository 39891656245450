import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import { RootState } from 'src/redux/store';
import { setVideoDuration, pauseVideo, playVideo, setVideoInRange, updateVideoProgress } from 'redux/slices/videoSlice';
import { rxjsAudioService } from '../../services/rxjsAudioService';
import ProgressBar from './ProgressBar';
import VideoInfo from './VideoInfo';
import styles from './VideoPlayer.module.css';

interface VideoPlayerProps {
  src: string;
  videoOpen: boolean;
  handlePlayPause?: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, videoOpen, handlePlayPause }) => {

  const dispatch = useDispatch();
  const debugMode = process.env.REACT_APP_DEBUG === 'true';
  const videoState = useSelector((state: RootState) => state.video);
  const playingTrackId = useSelector((state: RootState) => state.audio.playingTrackId);
  const selectedSongId = useSelector((state: RootState) => state.songList.selectedSongId);
  const waveSurferInstances = useSelector((state: RootState) => state.audio.waveSurferInstances);
  const videoInRange = useSelector((state: RootState) => state.video.videoInRange)
  const videoOffsetInSeconds = useSelector((state: RootState) => state.video.videoOffsetInSeconds);
  const waveSurferInstanceReady = useSelector((state: RootState) => selectedSongId ? state.audio.waveSurferInstances[selectedSongId] : null); 
  const videoPlayingStateRef = useRef<boolean>( videoState.isPlaying);
  const playTrackReference = useSelector((state: RootState) => state.trackReference.playTrackReference);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVideoInRangeRef = useRef<boolean | null>(true);
  const videoOffsetInSecondsRef = useRef<number>(0);
  const subscriptionRef = useRef<Subscription>(new Subscription());

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleLoadedMetadata = () => {
      dispatch(setVideoDuration(videoElement.duration));
    };
    const handleTimeUpdate = () => {
      dispatch(updateVideoProgress(videoElement.currentTime));
    };

    //video element listeners
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [dispatch]);


  //continuously check if audio/video are out of range
  //called from rxjsAudioService when videoOffsetInSeconds changes and observable events are triggered
  const handleIsOutOfBounds = useCallback((audioTimeInSeconds: number, source?: string) => {
    const videoElement = videoRef.current;
    if (!videoElement || !videoElement.duration) return;

    const videoTimeWithOffset = audioTimeInSeconds - videoOffsetInSecondsRef.current;
    const isVideoInRange = videoTimeWithOffset >= 0 && videoTimeWithOffset <= videoElement.duration;

    if (isVideoInRange) {
      // console.log('isVideoInRange true');
      //we only want to change the time when the source of change is a 
      //seek event or an offset change. 
      if (source !== 'audioProcess') { //seeked to a new position within the video range 
        videoElement.currentTime = videoTimeWithOffset;
      }
      //this is used when video enters the range from out of range and is paused
      // console.log('videoPlayingStateRef ', videoPlayingStateRef.current)
      if (videoElement.paused && videoPlayingStateRef.current) {
        videoElement.play();
      }

    } else {
      // console.log('isVideoInRange false');
      if (!videoElement.paused) {
        console.log('paused the video!')
        videoElement.pause();
      }
      ;
    }

    //check if isVideoInRange has changed
    if (isVideoInRange !== isVideoInRangeRef.current) {
      dispatch(setVideoInRange(isVideoInRange));
      if (isVideoInRange) {
        //this will cover the case where audioProcess crosses into the video range
        videoElement.currentTime = videoTimeWithOffset;
      }
      isVideoInRangeRef.current = isVideoInRange;
    }
  }, [dispatch]);

  useEffect(() => {
    console.log('selectedSongId changed: ', selectedSongId, waveSurferInstanceReady)
    //!make sure observable is created only after waveSurfer instance is created
    if (!selectedSongId || !waveSurferInstanceReady) {
      subscriptionRef.current.unsubscribe();
      return;
    }

    console.log('creating video observable for songId: ', selectedSongId)
    const observable = rxjsAudioService.createVideoTimelineObservable(selectedSongId);
    // Unsubscribe from the previous subscription if it exists
    subscriptionRef.current.unsubscribe();
    // Create a new subscription to audioProcess, seek and play events which pass the currentTime
    subscriptionRef.current = observable.subscribe(data => {
      if (data.source && data.currentTime) {
        handleIsOutOfBounds(data.currentTime, data.source);
      }
    });
    return () => subscriptionRef.current.unsubscribe();
  }, [selectedSongId]); //, waveSurferInstanceReady, handleIsOutOfBounds


  useEffect(() => {
    if (videoInRange) {
      playingTrackId ? dispatch(playVideo()) : dispatch(pauseVideo())
    } else {
      dispatch(pauseVideo());
    }
  }, [videoInRange]);

  //state has changed, update video accordingly - play/pause
  useEffect(() => {
    const videoElement = videoRef.current;
    videoPlayingStateRef.current = videoState.isPlaying;
    if (!videoElement) return;
    if (videoState.isPlaying) {
      const videoHasEnded = videoState.currentVideoTime === videoElement.duration;
      if (!videoHasEnded) {
        videoElement.play().catch(error => {
          console.error("Error during playback: ", error);
        });
      }
    } else {
      console.log('pausing video')
      videoElement.pause();
    }
  }, [videoState.isPlaying]);


  useEffect(() => {
    if (!selectedSongId || !waveSurferInstances[selectedSongId]) return;
    videoOffsetInSecondsRef.current = videoOffsetInSeconds;
    handleIsOutOfBounds(waveSurferInstances[selectedSongId].currentTime);
  }, [videoOffsetInSeconds]); //ideally this should not be called before waveSurferInstances is ready


  
  return (
    <>
      <video
        ref={videoRef}
        src={src}
        muted={true}
        controls={false}
        // onClick={handlePlayPause}
        className={!videoOpen ? styles.thumbnailVideo : ''}
        onLoadedMetadata={e => dispatch(setVideoDuration(e.currentTarget.duration))}
      />

      {debugMode &&
        <div className={styles.progressBarContainer}>
          <ProgressBar />
        </div>
      }
      {debugMode && <VideoInfo />}

    </>
  );
};

export default VideoPlayer;

import { FunctionComponent, useCallback, useState, FormEvent } from "react";
import { TextField, Button, ThemeProvider, createTheme } from "@mui/material";
import { useDispatch as useReduxDispatch } from 'react-redux';
import { AppDispatch } from 'src/redux/store';

import { loginUser } from 'redux/slices';
import { initializeFavorites } from 'redux/slices/favoritesSlice';
import { UserProfile } from 'src/types';
import styled from "styled-components";

const useDispatch = () => useReduxDispatch<AppDispatch>();

const theme = createTheme({

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginBottom: "10px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00e5ff",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            // borderColor: "rgba(255, 255, 255, 0.4)",
            borderColor: "#00e5ff",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(255, 255, 255, 0.4)",
          },
        },
        input: {
          color: '#ffffff',
          backgroundColor: "transparent",
          height: '10px',
          fontFamily: "Arial",  // Font family for input field

        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#00e5ff",
          },
          "&": {
            color: "rgba(255, 255, 255, 0.6)",
          }
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.0rem',
          "&.Mui-focused": {
            fontSize: '1.0rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#00e5ff",
          color: "#000",
          "&:hover": {
            backgroundColor: "#00b3bf"
          },
        },
      },
    },


  }
});


const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginTitle = styled.b`
  font-size: 28px;
  letter-spacing: 0.15px;
  line-height: 160%;
  color: #fff;
  text-align: center;
  font-family: 'Juice';
`;

const CenterText = styled.span`
  font-size: 10px;
  text-align: center;
  color: #9e9e9e;
  font-family: 'Juice';
  line-height: 1.6; // Increase line-height here
  a {
    color: inherit;
    text-decoration: underline;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: inherit;
    }
    &:active {
      color: inherit;
    }
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // This makes sure the container takes the whole height of the viewport
`;

// Style for the header
const Header = styled.h1`
  color: #ffffff;
  text-align: center;
  font-family: 'Juice';
  font-size: 18px;  // Decreased font-size
  margin-bottom: 50px;
  letter-spacing: 2px; // Letter spacing of 2px
`;

const LoginModal = styled.form`
  margin: 0 !important;
  border-radius: 12px;
  background-color:var(--main-colors-black-2);
  width: 450px;
  height: 400px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginModalInner = styled.div`
  padding: 20px 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const LoginHeader = styled(StyledDiv)`
  width: 100%;
  gap: 10px;
`;

const LoginFields = styled(StyledDiv)`
  width: 100%; // Adjust this to control the width of the TextFields
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  position: relative;
  isolation: isolate;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; // This will center the TextFields
  gap: 10px;
  padding: 0px;
  box-sizing: border-box;
  margin: 32px 0px 10px 0px;
`;


const LoginButtonContainer = styled(StyledDiv)`
  width: 100%;
  gap: 16px;
`;

const LoginButton = styled(Button)`
  align-self: stretch;
  cursor: pointer;
`;

const ForgotPasswordText = styled(StyledDiv)`
  position: relative;
  font-size: 13px;
  display: inline-block;
  color: #00e5ff;
  text-align: center;
  width: 100%;
  cursor: pointer;
  margin: 20px 0px 0px 0px;
`;

const SignInPage: FunctionComponent = () => {

  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(loginUser({ email, password })).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        const userId = (action.payload as UserProfile).email;
        dispatch(initializeFavorites(userId))
      }
    });
  };

  const handleForgotPasswordClick = useCallback(() => {
    // Handle forgot password click
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <PageContainer>
        <Header>MONSTER MUSIC</Header>
        <LoginModal onSubmit={handleSubmit}>
          <LoginModalInner>
            <LoginHeader>
              <LoginTitle>Log in</LoginTitle>
              <CenterText>
                <span>Don’t have an account? </span>
                <span>Get in contact</span>
              </CenterText>
            </LoginHeader>
            <LoginFields>
              <TextField
                color="primary"
                variant="outlined"
                type="email"
                label="Email address"
                value={email} // Bind value to state
                onChange={(e) => setEmail(e.target.value)} // Update state when input changes
                size="medium"
                margin="none"
                style={{ width: '100%' }}
              />
              <TextField
                color="primary"
                variant="outlined"
                type="password"
                label="Password"
                value={password} // Bind value to state
                onChange={(e) => setPassword(e.target.value)} // Update state when input changes
                size="medium"
                margin="none"
                style={{ width: '100%' }}
              />
            </LoginFields>
            <LoginButtonContainer>
              <LoginButton variant="contained" name="login" color="primary" type="submit">
                Log in
              </LoginButton>
              <CenterText>
                {`By continuing, you accept our `}
                <a href="/terms-and-conditions">Terms and Conditions</a>

                {` and `}  <br />
                <a href="/privacy-policy">Privacy Policy</a>.
              </CenterText>
            </LoginButtonContainer>
            <ForgotPasswordText onClick={handleForgotPasswordClick}>
              Forgot password?
            </ForgotPasswordText>
          </LoginModalInner>
        </LoginModal>
      </PageContainer>
    </ThemeProvider>
  );
};

export default SignInPage;

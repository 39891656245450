// src/features/playBar/containers/PlayBarComponent.tsx
import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import '../../../styles/tippy.css';
import { SkipPrevious, SkipNext } from '@mui/icons-material';
import truncate from 'lodash/truncate';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ArtCoverComponent from './ArtCoverComponent';
import SongDetails from './SongDetails';
import TimeValues from './TimeValues';
import FavoriteButton from '../../../components/shared/FavoriteButton';
import DownloadButton from './DownloadButton';
import VideoButton from './VideoButton';
import VolumeControl from './VolumeControl';
import SoundWavePlaybarContainer from 'components/media/SoundWavePlaybarContainer';
import VideoPlayer from 'components/media/VideoPlayer';
import CustomSwitch from '../../../components/shared/CustomSwitch';
import usePlayBarController from '../hooks/usePlayBarController';
import styles from './PlayBar.module.css';

// This is the PlayBarComponent that is used below the playlists and mirrors the SoundWaveComponent with minimap set to true (default).

const SelectTrackMessage = () => (
  <div className={styles.selectTrackMessage}>
    Loading track ...
  </div>
);

const iconButtonStyles = {
  color: 'var(--main-colors-white)',
  margin: '7px',
  '&:hover': {
    backgroundColor: 'var(--main-colors-black-4)',
  }
};

const PlayBarComponent = () => {
  const {
    videoOutOfRangeWarning,
    selectedSong,
    videoOpen,
    isFullscreen,
    showIntroBubble,
    setShowIntroBubble,
    trackReferenceUrl,
    playTrackReference,
    videoLink,
    isVideoAvailable,
    isPlaying,
    handleNextSong,
    handlePreviousSong,
    handleDownload,
    handleVideo,
    handlePlayPause,
    toggleTrackReference,
    handleFullscreen,
    isAtStart,
    isAtEnd,
    oneResult
  } = usePlayBarController();

  return (
    <div className={videoOpen ? styles.fullscreenContainer : styles.container}>
      {selectedSong && videoLink && videoOpen && (
        <div className={isFullscreen ? styles.fullscreenVideoContainer : styles.videoContainer}>
          <VideoPlayer src={videoLink} videoOpen={videoOpen} />
          {selectedSong && (
            <div className={styles.fullScreenSongDetailsContainer}>
              <SongDetails title={truncate(selectedSong.title, { length: 20 })} artist={selectedSong.artist} showIcon={true} />
            </div>
          )}
          {videoOutOfRangeWarning && (
            <div className={styles.blackScreen}>
              <div className={styles.blackScreenText}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <WarningAmberIcon style={{ marginRight: '8px' }} />
                  <h2 style={{ margin: 0 }}>Audio Out of Range</h2>
                </div>
                Keep the audio within the video timeline rectangle.
              </div>
            </div>
          )}
            <div className={styles.playIcon} onClick={handlePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </div>
          <div className={styles.fullscreenIcon} onClick={handleFullscreen}><FullscreenIcon /></div>
        </div>
      )}

      <div className={styles.playBarContainer}>
        {selectedSong ? (
          <>
            <div className={styles.playBarElements}>
              <div className={styles.toggleReferenceTrackContainer}>
                {trackReferenceUrl && (
                  <CustomSwitch
                    checked={playTrackReference}
                    onToggle={toggleTrackReference}
                    text='Original track'
                    textStyle={{ fontWeight: '700', color: playTrackReference ?  'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0.2)' }}
                  />
                )}
              </div>
              <div className={styles.iconContainer}>
                {!isAtStart && !oneResult && !playTrackReference &&<SkipPrevious sx={iconButtonStyles} onClick={handlePreviousSong} />}
                <ArtCoverComponent id={selectedSong.id} hovered={true} coverSleeveUrl={selectedSong.coverSleeveUrl} />
                {!isAtEnd && !oneResult && !playTrackReference &&<SkipNext sx={iconButtonStyles} onClick={handleNextSong} />}
              </div>
              <div className={`${styles.songDetailsContainer}`}>
                <SongDetails title={truncate(selectedSong.title, { length: 20 })} artist={selectedSong.artist} />
              </div>

              {/* The SoundWavePlaybarContainer hosts a minimap (clone of the soundWaveComponent) or the soundWaveComponent itself.  
                 UseCase 1: When a track is selected in the library, the soundWaveComponent is cloned and the clone is populated SoundWavePlaybarContainer as a minimap
                 UseCase 2: In case of an orphan track such as with an Original Track which is only populated in the playbar , SoundWaveComponent is directly populated in the soundWavePlaybarContainer.
                 with UseCase 2 set minimap to false */}

                 <SoundWavePlaybarContainer id={selectedSong.id} />


              <div className={styles.timeValues}>
                <TimeValues bpm={selectedSong.bpm} duration={selectedSong.duration} id={selectedSong.id} />
              </div>
              <VolumeControl />
              <div className={styles.favoritesButtonContainer}>
                <FavoriteButton songProps={selectedSong} />
              </div>
              {isVideoAvailable && (
                <Tippy
                  content="Toggle Video"
                  arrow={true}
                  placement='top'
                  visible={showIntroBubble}
                  offset={[0, 4]}
                  theme='customTippyTheme'
                  onClickOutside={() => setShowIntroBubble(false)}
                >
                  <VideoButton onVideo={handleVideo} isOpen={!videoOpen} />
                </Tippy>
              )}
              <DownloadButton onDownload={handleDownload} />
            </div>
          </>
        ) : (
          <SelectTrackMessage />
        )}
      </div>
    </div>
  );
};

export default PlayBarComponent;

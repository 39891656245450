import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import WaveSurfer from '@cyborganic/wavemonster.js';
import Minimap from '@cyborganic/wavemonster.js/dist/plugins/minimap.js';
import Hover from '@cyborganic/wavemonster.js/dist/plugins/hover.js';
import { useSelector } from 'react-redux';
import { SoundWaveComponentProps } from 'src/types';
import { stopTrack, updateWaveSurferInstance, createWaveSurferInstance, destroyWaveSurferInstance } from 'redux/slices';
import { manageMediaPlayback } from 'redux/thunks/manageMediaPlaybackThunks';
import { RootState } from 'src/redux/store';
import { useAppDispatch } from 'hooks';
import { waveSurferManager } from '../../services/waveSurferManager';
import styles from './SoundWaveComponent.module.css';
import throttle from 'lodash/throttle';

const SoundWaveComponent: React.FC<SoundWaveComponentProps> = React.memo(({ id, audioUrl, peaksUrl, onTrackReady, minimap = true, duration }) => {
  // console.log('SoundWaveComponent audioUrl  ', id, audioUrl);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const waveSurferElementRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const volume = useSelector((state: RootState) => state.audio.waveSurferInstances[id]?.volume) || 0;
  const playPauseState = useSelector((state: RootState) => state.audio.waveSurferInstances[id]?.playPauseState);
  const seek = useSelector((state: RootState) => state.audio.waveSurferInstances[id]?.seek) || 0;
  const updateTrigger = useSelector((state: RootState) => state.audio.waveSurferInstances[id]?.updateTrigger);
 
  const prevVolRef = useRef(volume);  // to prevent re-rendering when the volume value is the same
  const prevSeekRef = useRef(seek);  // to prevent re-rendering when the seek value is the same

  const [isLoading, setIsLoading] = useState(true);
  const [lastSeekPosition, setLastSeekPosition] = useState<number>(0);
  const minimapContainerId = `minimap-container-${id}`;
  const [cursorX, setCursorX] = useState<number | null>(null);
  const [cursorTime, setCursorTime] = useState<string | null>(null);

  const fetchPeaksData = async (peaksUrl: string): Promise<number[]> => {
    // console.log('fetchPeaksData ', peaksUrl);
    const response = await fetch(peaksUrl);
    const peaksData = await response.json();
    return peaksData;
  };

  const handlePlayPause = () => {
    if (waveSurferRef.current) {
      if (playPauseState && waveSurferRef.current.isPlaying()) {
        console.log('handlePlayPause continue as is');
        return;
      } else if (playPauseState && !waveSurferRef.current.isPlaying()) {
        console.log('handlePlayPause current.play() new track');
        waveSurferRef.current.play();
      } else {
        console.log('handlePlayPause stopTrack');
        dispatch(stopTrack());
        console.log('handlePlayPause manageMediaPlayback play');
        dispatch(manageMediaPlayback(id, true));
      }
    }
  };

  const handleWaveSurferReady = (duration:number) => {
    if (waveSurferRef.current) {
      if (minimap) {
        const minimapElement: Element | null = document.querySelector(`#${minimapContainerId}`);
        if (minimapElement && minimapElement instanceof HTMLElement) {
          minimapElement.classList.add(`minimap-${id}`, 'minimap-hidden');
        }
      }
      dispatch(createWaveSurferInstance(id));
      dispatch(updateWaveSurferInstance({
        instanceId: id,
        changes: { duration: duration}
      }));

      if (onTrackReady) onTrackReady(id);
      setIsLoading(false);
    }
  };

  const handleClearMinimapFromContainer = () => {
    const minimapContainer = document.getElementById(minimapContainerId);
    if (minimapContainer) {
      while (minimapContainer.firstChild) {
        minimapContainer.removeChild(minimapContainer.firstChild);
      }
    }
  };

  const updateCurrentTimeState = (currentTime: number) => {
    if (isFinite(currentTime)) {
      dispatch(updateWaveSurferInstance({
        instanceId: id,
        changes: { currentTime: currentTime }
      }));
    }
  };

  const initializeWaveSurfer = async () => {
    if (waveSurferElementRef.current) {
      
      const peaks:any = (peaksUrl)? await fetchPeaksData(peaksUrl):undefined;
    
      const config = getWaveSurferConfig(waveSurferElementRef, minimapContainerId, minimap,audioUrl, peaks, duration );
      if (config && !waveSurferRef.current) {
        waveSurferRef.current = waveSurferManager.createInstance(id, config);

        if (!waveSurferRef.current) {
          console.error('Failed to create WaveSurfer instance');
          return;
        }


        waveSurferRef.current.on('finish', () => {
          dispatch(manageMediaPlayback(null, false));
        });

        waveSurferRef.current.on('ready', (duration) => {
          //console.log('rendered peaks by wavesurfer ', id, duration);
          // if(duration && !peaks && !minimap){
            if(duration){
            // realtime waveform peaks creation required (this is for reference tracks uploaded where we have no peaks file to load)
            handleWaveSurferReady(duration);
          }
        });


        waveSurferRef.current.on('audioprocess', throttle((currentTime) => {
          updateCurrentTimeState(currentTime);
        }, 1000));

        waveSurferRef.current.on('seeking', (currentTime) => {
          setLastSeekPosition(currentTime);
        });

        //when peaks are provided we can call handleWaveSurferReady directly, this speeds up the rendering
        //if(peaks && duration){
          //this has been commented out because we want to wait for the waveform to be rendered before calling handleWaveSurferReady
          //otherwise some actions such as hover on the waveform will show before the waveform is rendered
          //handleWaveSurferReady(duration);
        // } 
      }
    }
  };

  const destroyWavesurfer = () => {
    if (waveSurferRef.current) {
      handleClearMinimapFromContainer();
      waveSurferRef.current.destroy();
      waveSurferManager.destroyInstance(id);
      dispatch(destroyWaveSurferInstance(id));
      waveSurferRef.current = null;
    }
  };

  interface WaveSurferConfig {
    container: HTMLElement | string;
    waveColor: string;
    progressColor: string;
    barHeight: number;
    height: number;
    dragToSeek: boolean;
    cursorWidth: number;
    plugins: Array<any>;
    url: string;
    peaks?:  Array<Float32Array | number[]>;
    duration?: number;
  }

  

  const getWaveSurferConfig = (
    waveSurferElementRef: React.RefObject<HTMLElement>,
    minimapContainerId: string,
    minimap: boolean = true,
    audioUrl:string,
    peaks?:   Array<Float32Array | number[]>,
    duration?: number,
  ): WaveSurferConfig | null => {
    if (!waveSurferElementRef.current) {
      return null;
    }
    const config: WaveSurferConfig = {
      container: waveSurferElementRef.current,
      waveColor: '#635f5e',
      progressColor: '#00BCD4',
      barHeight: 1,
      height: minimap ? 50 : 40,
      dragToSeek: false,
      cursorWidth: 0,
      peaks: peaks,  // Precomputed waveform data
      duration: duration,  // Duration of the audio
      url: audioUrl,  // URL for the audio file
      plugins: []
    };

    if (minimap) {
      config.plugins.push(
        Minimap.create({
          container: `#${minimapContainerId}`,
          height: 40,
          waveColor: '#635f5e',
          progressColor: '#00BCD4',
          barHeight: 1,
          cursorWidth: 0,
        }),
        Hover.create({
          lineColor: '#ffffff',
          lineWidth: 1,
          labelBackground: '#444',
          labelColor: '#fff',
          labelSize: '11px',
        })
      );
    }
    return config;
  };

  useEffect(() => {
    if (waveSurferRef.current && playPauseState !== undefined) {
  
      if (playPauseState === true && !waveSurferRef.current.isPlaying()) {
        // Just call play, WaveSurfer will load the audio if necessary
        waveSurferRef.current.play();
      } else {
        waveSurferRef.current.pause();
      }
    }
  }, [playPauseState]);

  // useEffect(() => {
  //   if (waveSurferRef.current && playPauseState !== undefined) {
  //     if (playPauseState === true && !waveSurferRef.current.isPlaying()) {
  //       // Load audio on first play
  //       if (!waveSurferRef.current.getDuration()) {
  //         waveSurferRef.current.load(audioUrl).then(() => {
  //           waveSurferRef.current!.play();
  //         });
  //       } else {
  //         waveSurferRef.current.play();
  //       }
  //     } else {
  //       waveSurferRef.current.pause();
  //     }
  //   }
  // }, [playPauseState]);

  useEffect(() => {
    if (waveSurferRef.current && isFinite(seek) && prevSeekRef.current !== seek) {
      waveSurferRef.current.seekTo(seek);
      updateCurrentTimeState(waveSurferRef.current.getCurrentTime());
    }
  }, [seek, updateTrigger]);

  useEffect(() => {
    if (waveSurferRef.current && volume >= 0 && volume <= 100 && prevVolRef.current !== volume ) {
      const normalizedVolume = volume / 100;
      waveSurferRef.current.setVolume(normalizedVolume);
      //Update the prevVolRef to the current volume
      prevVolRef.current = volume;
    }
  }, [volume]);

  useLayoutEffect(() => {
    initializeWaveSurfer();
    return () => {
      destroyWavesurfer();
    };
  }, [peaksUrl, audioUrl]);

  return (
    <div className={styles.waveFormContainer}>
      {isLoading && <Skeleton variant="rectangular" height={50} width="100%" animation="wave" sx={{ bgcolor: 'var(--main-colors-black-2)' }} />}
      <div key={id}>
        <div onClick={handlePlayPause} ref={waveSurferElementRef} style={{ width: '100%', display: isLoading ? 'none' : 'block' }}></div>
        {minimap && (<div id={minimapContainerId}></div>)}
      </div>
    </div>
 );
}, (prevProps, nextProps) => {
  return prevProps.id === nextProps.id && prevProps.audioUrl === nextProps.audioUrl;
});

export default SoundWaveComponent;

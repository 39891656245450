import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserState, UserProfile } from 'src/types';  
import fetchUser from '../../services/loginService'; // Adjust the path as necessary

const checkAuthStatus = () => {
  const isAuth = localStorage.getItem('isAuth');
  const storedExpiryTime = localStorage.getItem('expiryTime');
  const currentTime = new Date().getTime();

  if (isAuth && storedExpiryTime && parseInt(storedExpiryTime) > currentTime) {
    return true;
  }

  localStorage.removeItem('isAuth');
  localStorage.removeItem('expiryTime');
  localStorage.removeItem('userProfile');
  return false;
};

const initialState: UserState = {
  isAuth: checkAuthStatus(),
  loading: false,
  error: null,
  profile: JSON.parse(localStorage.getItem('userProfile') || 'null'),
};

// Todo: for production change to Use Secure HTTP-Only Cookies for Session Management!!
// current implementation is not secure!!!
// See https://github.com/cyborganic/prototype.monstermusic.io/issues/112
export const loginUser = createAsyncThunk<UserProfile, { email: string; password: string }, { rejectValue: string }>(
  'user/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const userProfile = await fetchUser(email, password); // Assuming this returns UserProfile
      const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
      localStorage.setItem('isAuth', 'true');
      localStorage.setItem('expiryTime', expiryTime.toString());
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
      return userProfile;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    logout: (state) => {
      state.isAuth = false;
      state.profile = null;
      localStorage.removeItem('isAuth');
      localStorage.removeItem('expiryTime');
      localStorage.removeItem('userProfile');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuth = true;
        state.loading = false;
        // console.log('this is the user payload ', JSON.stringify(action.payload));
        state.profile = action.payload || null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setLoading, logout } = userSlice.actions;
export default userSlice.reducer;
